import React from 'react';
import {RiHome2Line, 
    RiSendPlaneLine,
    RiHistoryLine
} from 'react-icons/ri';
import {MdCloudQueue} from 'react-icons/md'
import {Link} from 'react-router-dom';
const SIZE=20;
export default [
    {
        key:'/endpoint',
        icon:<RiSendPlaneLine size={SIZE}/>,
        label:<Link to={'/endpoint'}>Endpoint</Link>,
        level:0x1ff0,
    },
    {
        key:'/logs',
        icon:<RiHistoryLine size={SIZE}/>,
        label:<Link to={'/logs'}>Logs</Link>,
        level:0x1ff0,        
    },
    {
        key:'/queue',
        icon:<MdCloudQueue size={SIZE}/>,
        level:0x1ff0,
        label:<Link to={'/queue'}>Queue</Link>,
    }
]
import EndpointPage from './endpoint'
import EndpointForm from './endpoint/form';
import LogsPage from './logs';
import QueuePage from './queue';
export const Routing = [
    {
        to:'/queue',
        level:0x1fff,
        component: <QueuePage />
    },
    {
        to:'/logs',
        level:0x1fff,
        component: <LogsPage />
    },
    {
        to:'/endpoint',
        level:0x1ff0,
        component: <EndpointPage />
    },
    {
        to:'/endpoint/create',
        level:0x1ff0,
        component: <EndpointForm />
    },
    {
        to:'/endpoint/edit',
        level:0x1ff0,
        component: <EndpointForm />
    },
]
import React from 'react'
import TablePage from '../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.name,
            name:"Name",
        },
        {
            selector:(row)=>row?.url,
            name:"URL",
            grow:2
        },
        {
            selector:(row)=>row?.smi,
            name:"SMI",
        },
        {
            selector:(row)=>(row?.lastRequest && moment.duration(moment(row.lastRequest).diff(moment())).humanize()) || '-',
            name:"Last Request",
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
        },
    ]
    return (
        <TablePage
            title={"Endpoint"}
            url="api/v1/endpoint"
            actionPosition={'first'}
            createPath="/endpoint/create"
            editPath={"/endpoint/edit"}
            columns={columns}            
        />
    )
}
import React from 'react'
import TablePage from '../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.endpoint_id?.name,
            name:"Endpoint",
        },
        {
            selector:(row)=>row?.file_id,
            name:"FileId",
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
        },
    ]
    return (
        <TablePage
            title={"Queue"}
            url="api/v1/queue"
            actionPosition={'first'}
            columns={columns}            
        />
    )
}
import React from 'react'
import TablePage from '../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.endpoint_id?.name,
            name:"Endpoint",
        },
        {
            selector:(row)=>row?.corid,
            name:"CorId",
        },
        {
            selector:(row)=>row?.smi,
            name:"SMI"
        },
        {
            selector:(row)=>row?.msg,
            name:"Status",
            wrap:true,
        },
        {
            selector:(row)=>row?.resp?.message || row?.resp?.data ,
            name:"Resp",
            wrap:true,
        },
        {
            selector:(row)=>row?.timeSend,
            name:"Time(ms)",
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
        },
    ]
    return (
        <TablePage
            title={"Logs"}
            url="api/v1/telex_log"
            actionPosition={'first'}
            columns={columns}            
        />
    )
}
import { Form, Input } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../components/pages/FormPage'
import {useSelector} from 'react-redux'

const DefModel={
    url:'',
    smi:'',
    name:'',
}

export default ()=>{
    const [model, setModel]=useState(DefModel);
    return (
        <FormPage 
            url={'api/v1/endpoint'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{span:12}}
            formSize={'large'}
            labelCol={{span:3}}
            title={"EndPoint"}            
            emptyModel={DefModel}
            callbackPath={'/endpoint'}
        >
            <Form.Item name={'name'} label="Endpoint Name" rules={[{required:true}]} >
                <Input autoFocus autoComplete='OFF' autoCapitalize='uppercase' />
            </Form.Item>
            <Form.Item name={'smi'} label="SMI" >
                <Input  />
            </Form.Item>
            <Form.Item name={'url'} label="URL" rules={[{required:true}]}>
                <Input  />
            </Form.Item>
        </FormPage>
    )
}
import React, { useEffect } from 'react'
import {SiderLayout} from '../../../components/layouts/sider';
import Menus from './menu'
import {green} from '@ant-design/colors'
import { useDispatch, useSelector } from 'react-redux';
import {Routing} from './routing'
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { useLocHref } from '../../../hooks/useLocHref';
import {useMenu} from '../../../hooks/useMenu';
import { useNavs } from '../../../hooks/useNavs';
import CFG from '../../../config/env';
const {Title} = CFG;
const TheRouter = ()=>{
    const {userdata:{level}} = useSelector(state=>state.auth);
    const routes=Routing.filter(r => (r.level & level)>0);

    return (
        <Switch>
            {
                routes.map((route, idx)=>(
                    <Route key={idx} path={route.to} exact={route.isExact!==false} render={props=>{
                        return !!route.component && route.component
                    }} />
                ))
            }
            <Route path={'/*'}>
                <div>Page Not Found</div>
            </Route>
        </Switch>
    )
}

export default ({idx}) =>{
    const {userdata:{level}} =useSelector(state=>state.auth);
    const key=useLocHref('dashboard');
    const {keys, menus}=useMenu(Menus, level);
    useNavs(keys, key);
    return (
        <SiderLayout 
            menus={menus} 
            headerBgColor={green[8]}
            headerColor={"white"}
            title={Title}
        >
            <TheRouter />
        </SiderLayout>
    )
}